import React, {
    useRef,
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import {
    getDesignConfig
} from '../features/studio/studioSlice';

import { isMobile } from 'react-device-detect';
import { PlayButton } from '.';


export const VideoDisplay = forwardRef((props, ref) => {
    
    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(false);
    const designConfig = useSelector(getDesignConfig); console.log(designConfig);
    const welcome_poster = designConfig.welcome_poster;
    
    const playVideo = () => {
        if (videoRef.current) {
            if (!playing) {
                videoRef.current.currentTime = 0;
                videoRef.current.play();
                setPlaying(true);
                props.playing(true);
            } else {
                props.playing(false);
                setPlaying(false);
            }
        }
    };

    const playMute = () => {
        if (videoRef.current) {
            videoRef.current.muted = true;
            videoRef.current.play();
        }
    };

    useEffect(() => {
        videoRef.current.setAttribute('poster', props.designConfig.welcome_poster);
        videoRef.current.setAttribute('src', props.designConfig.welcome_video);

        setTimeout(() => {
            playMute();
        }, 500);
    }, []);

    useImperativeHandle(ref, () => ({
        playVideoRef() {
            playVideo();
        },
    }));

    const handleVideoEnded = () => {
    // console.log('Video ended!');
        // if (playing) {
            //playVideo();
            if (videoRef.current) {
                props.playing(false);
                setPlaying(false);
            }
        // }
    };

    return (
        <div className='shoutout_video_dispaly'>
            {/* <div className="shoutout_video_bg" style={{ backgroundImage: `url(./images/shoutout/bg.jpg)`, 'opacity' : playing ? 0 : 1  }} ></div>  */}
            <div
                className={`shoutout_video_player ${
                    isMobile ? 'video_landscape' : 'video_portrait'
                }`}>
                <video
                    poster={welcome_poster}
                    onEnded={handleVideoEnded}
                    ref={videoRef}
                    playsInline
                    muted={!playing}
                    style={{ opacity: playing ? 1 : 0.7 }}></video>
   
            </div>
            <div className='shoutout_video_bg_action'>
                <h3>{/*👋 Hi form StoriCam*/}</h3>
                <PlayButton playing={playing} onClick={() => playVideo()} />

            </div>
        </div>
    );
});
