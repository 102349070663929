import React, { useEffect, useState, useRef } from "react";
import { useDispatch, 
  // useSelector 
} from "react-redux";
import axios from "axios";
import {
  // getUserData,
  setDesignConfig
} from "./features/studio/studioSlice";

//import { Laravel } from ".";

import { TourProvider } from "@reactour/tour";
import { tourConfig } from "./features/studio/steps";

import MobileLandsapeMessage from "@src/components/MobileLandsapeMessage";
import { Loader } from "@src/components";

import { Welcome } from "@src/pages/Welcome";
import UserDetail from "@src/pages/UserDetail";
import { GetStart } from "@src/pages/GetStart";
import Booth from "@src/pages/Booth";
import { Text } from "@src/pages/Text";
import { CropPage } from "@src/pages/CropPage";
import { Decision } from "@src/pages/Decision";
import TextShoutout from "@src/pages/TextShoutout";
import { Legal } from "@src/pages/Legal";
import { Thanks } from "@src/pages/Thanks";
import { CalenderPage } from "@src/pages/CalenderPage";
import { HardwareTest } from "@src/pages/HardwareTest";
import { TextPreview } from "@src/pages/TextPreview";
import { RecordAudio } from "@src/pages/RecordAudio";
import { RecordText } from "@src/pages/RecordText";


import { Routes, Route, Navigate } from "react-router-dom";

export function Studio() {
  const childRef = useRef(null);
  const dispatch = useDispatch();
  // const userData = useSelector(getUserData);

  // console.log("USER DATA", userData);

  const [loading, setLoading] = useState(true);
  const [step, setStep] = React.useState(0);

  const pathname = document.location.pathname;
  const pathInfo = pathname.replace(/^\/+/, "").split("/");
  const storiurl = process.env.REACT_APP_HUB_URL;
  //alert(pathInfo[0]);
  //alert(process.env.REACT_APP_HUB_URL);

  useEffect(() => {

    if( pathInfo[0] != '' ){
      axios.get(`${storiurl}/getShoutoutNewDesign/${pathInfo[0]}`).then((res) => {
        // console.log(res.data);
        console.log('from studio.js');
        document.body.style.background = res.data.body_bg_color;
        document.body.style.color = res.data.body_text_color;
        dispatch(setDesignConfig(res.data));
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  const beforeClose = () => {
    childRef.current.closeTour();
  };

  useEffect(() => {
    // if (userData) {
    //   navigate("/welcome", { replace: true });
    // } else {
    //   navigate("/", { replace: true });
    // }
  }, []);

  return (
    <>
      <MobileLandsapeMessage />
      {loading ? (
        <div style={{ height: "100vh" }}>
          <Loader loading={true} />
        </div>
      ) : (
        <Routes>
          <Route path='/' element={<Welcome />} />
          <Route path='/get-start' element={<GetStart />} />
          <Route path='/calender' element={<CalenderPage />} />
          <Route path='/test' element={<HardwareTest />} />
          <Route
            path='/textCapture'
            element={
              <TourProvider
                {...tourConfig}
                currentStep={step}
                setCurrentStep={setStep}
                beforeClose={beforeClose}>
                <TextShoutout setStep={setStep} step={step} ref={childRef} />
              </TourProvider>
            }
          />
          <Route path='/textpreview' element={<TextPreview />} />
          
          <Route path='/booth' element={<Booth />} />
          <Route path='/record' element={<RecordAudio />} />
          <Route path='/crop' element={<CropPage />} />
          <Route path='/text' element={<Text />} />
          {/* <Route path='/preview' element={<Preview />} /> */}
          <Route path='/hardware' element={<HardwareTest />} />
          <Route path='/decision' element={<Decision />} />
          <Route path='/legal' element={<Legal />} />
          <Route path='/user' element={<UserDetail />} />
          <Route path='/thanks' element={<Thanks />} />
          <Route path='/recordtext' element={<RecordText />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      )}
    </>
  );
}
