import React from 'react';
import { 
    //useDispatch, 
    useSelector } 
from 'react-redux';
import {
    //changeScreen,
    getDesignConfig
} from '@src/features/studio/studioSlice';
import { useNavigate } from 'react-router-dom';


export const CameraActions = () => {
    const camra_btn_bBack = require('@src/assets/images/camra_btn_bBack.svg').default;
    //const dispatch = useDispatch();
    const navigate = useNavigate();
    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    const shoutout_questions_count      = designConfig.shoutout_questions_count;            //console.log('shoutout_questions_count: ' + shoutout_questions_count);
    const navigate_link = (shoutout_questions_count > 0) ? '/get-start' : '/';

    return (
        <div className='shoutout_camra_action'>
            <div className='shoutout_camra_action_btnGroup'>
                <div className='shoutout_camra_action_btnGroup_left'>
                    <div className='shoutout_camra_action_btn camra_btn_bBack'>
                        <button
                            className='btn btn-dark'
                            onClick={() => {
                                //dispatch(changeScreen('get-start'));
                                navigate(navigate_link);
                            }}>
                            <img src={camra_btn_bBack} style={{ width: '20px' }} alt='' />
                        </button>
                        <span>Back</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
