import React from 'react';
//import { useSelector } from 'react-redux';
//import {getDesignConfig} from '../features/studio/studioSlice';

export const CopyRights = () => {

    //const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    //console.log(designConfig.powered_by_logo);
    
    // https://shoutoutswork.com/wp-content/uploads/2023/01/shoutout-logo.svg

    return (
        <div className='shoutout_copyright'>
      Powered by:
            <a
                target='_blank'
                href='https://shoutoutswork.com'
                style={{ display: 'inlineBlock', marginLeft: '6px' }}
                rel='noreferrer'>
                <img
                    src='https://shoutoutswork.com/wp-content/uploads/2023/01/shoutout-logo.svg'
                    style={{ height: '18px' }}
                    alt=''
                />
            </a>
        </div>
    );
};
