import React from 'react';

export const Loader = ({loading}) => {
    
    return (
        <div className="loders_wrap">
            <div className="loders_box">
                <div className="loders">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                {loading && <div className="loders_txt">LOADING</div> }
            </div>
        </div>
    );
};
