import React, { 
    useRef,
    useState
} from 'react';
import { useSelector } from 'react-redux';
import {
    getDesignConfig,
    setExperience,
} from '@src/features/studio/studioSlice';

import { useNavigate } from 'react-router-dom';

import TwoColumnLayout from '@src/layout/TwoColumnLayout';

import { isMobile } from 'react-device-detect';

import { MainLogo,  PlayButton } from '@src/components';


export const Welcome = () => {

    const childRef = useRef();
	const [playing, setPlaying] = useState(false);
    const [exp, setExp] = useState('video'); console.log(setExp); //console.log(exp);
    //
    const pathname = document.location.pathname;
    const pathInfo = pathname.replace(/^\/+/, "").split("/"); //console.log(pathInfo);
    const show_welcome = (pathInfo[0] != '') ? 'block' : 'none'; //console.log('show_welcome: ' + show_welcome);

    //--------------------------------------------------------------
    // removing question data from Storage
    //--------------------------------------------------------------
    localStorage.removeItem('max_question_video_duration');
    localStorage.removeItem('cur_q_num');
    localStorage.removeItem('cur_q');
    localStorage.removeItem('cur_q_id');
    //
    localStorage.removeItem('initial_minute');
    localStorage.removeItem('initial_seconds');
    //
    localStorage.removeItem('shoutout_question_responses_ids');
    //--------------------------------------------------------------

    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    const bodyTextColor = designConfig.body_text_color;
    const bgColor = designConfig.btn_bg_color;
    const btnTextColor = designConfig.btn_text_color;
    const [loading] = useState(false);

    const navigate = useNavigate();

    //
    const shoutout_questions_count      = designConfig.shoutout_questions_count;            //console.log('shoutout_questions_count: ' + shoutout_questions_count);
    const shoutout_questions_total_time = designConfig.shoutout_questions_total_time;       //console.log('shoutout_questions_total_time: ' + shoutout_questions_total_time);
    const welcome_message = designConfig.welcome_message;

    const qtext = (shoutout_questions_count > 1) ? 'questions' : 'question';
    const mtext = (shoutout_questions_total_time > 1) ? 'minutes' : 'minute';

    const show_question_info = (shoutout_questions_count > 0 && show_welcome == 'block') ? 'block' : 'none'; //console.log('show_question_info: ' + show_question_info);
    const next_page_link = (shoutout_questions_count > 0) ? '/get-start' : '/booth';

    //console.log(designConfig);
    //alert(pathInfo[0] +' - '+ pathInfo.length);

    const toggleVideo = () => {
        childRef.current.playVideoRef();
        setPlaying(!playing);
    }

    const onEnd = (isplaying) => {
        setPlaying(isplaying);
    }

    const setExperiences = () => {
        setExperience(exp);
        navigate(next_page_link);
    };

    return (
        <TwoColumnLayout loading={loading} columnOverlap={true} uploadingClass="welcome-section" ref={childRef} onEnd={onEnd}> 

            <div className='shoutout_welcome'>
                {/* {!isMobile ? 'play' : "pause"} */}
                {((!playing && isMobile) || !isMobile) &&

                <>
                <MainLogo hideMaxWidth={1} />
                
                <h3>{welcome_message}</h3>
                </>
                }
                { isMobile && <PlayButton playing={playing} onClick={toggleVideo} /> }
                
                
                {((!playing && isMobile) || !isMobile) &&

                <>
                <div className='shoutout_welcome_btn' style={{display: show_welcome}}>

                    <button className='btn btn-primary'
                        onClick={() => setExperiences('video')}
                        style={{backgroundColor: bgColor,
                            borderColor: bgColor,
                            color: btnTextColor,
                        }}>
                        <span>I&apos;m ready, let&apos;s go!</span>
                    </button>
                    <button className='btn btn-outline-primary'
                        onClick={() => navigate('/calender')}
                        style={{
                            borderColor: bgColor,
                            color: btnTextColor,
                        }}>
                        <span>I promise I will do it later</span>
                    </button>
                </div>
                <h6 style={{ color: bodyTextColor, display: show_question_info }}> {shoutout_questions_count} {qtext} - {shoutout_questions_total_time} {mtext}</h6>
                </>
                }
            </div>


        </TwoColumnLayout>
    );

};
