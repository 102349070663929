import {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
    useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    changeScreen,
    getDesignConfig,
    getUserData,
    setTestimonial,
} from '../features/studio/studioSlice';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTour } from '@reactour/tour';

const TextShoutout = forwardRef((props, refs) => {
    const { setIsOpen } = useTour();
    const camra_btn_bBack = require('@src/assets/images/camra_btn_bBack.svg').default;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const designConfig = useSelector(getDesignConfig);
    const userData = useSelector(getUserData);
    console.log(userData);
    const messageRef = useRef(null);
    const { register, handleSubmit, formState } = useForm({
        defaultValues: {
            message: '',
            fullName: userData.firstName + ' ' + userData.lastName,
            destination: '',
        },
    });
    const { errors } = formState;
    const { ref, ...rest } = register('message');

    const [charCount, setCharCout] = useState(0);
    const MAX_COUNT = 165;

    useImperativeHandle(refs, () => ({
        closeTour() {
            localStorage.setItem('tour_complete', 'true');
            messageRef.current.focus();
        },
    }));

    useEffect(() => {
        const tourComplete = localStorage.getItem('tour_complete');
        if (tourComplete !== 'true') {
            setIsOpen(true);
        }
    }, []);

    const captureData = (data) => {
    
        if (data.message === '') {
            toast('Shoutout is empty.');
            return false;
        }

        dispatch(
            setTestimonial({
                message: data.message,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                designation: data?.designation,
            })
        );
        dispatch(changeScreen('booth'));
        navigate('/booth');
    };

    const handleTextCounter = (e) => {
        console.log(e.target.value.length);
        setCharCout(e.target.value.length);
    };

    const bodyBgColor = designConfig.body_bg_color;

    return (
        <div className={'shoutout_text_wrap'} style={{backgroundColor: bodyBgColor,}}>
            <div className="back_btn">
                <button
                    className='btn btn-dark note_btn_back'
                    onClick={() => {
                        dispatch(changeScreen('welcome'));
                        navigate('/welcome');
                    }}>
                    <img src={camra_btn_bBack} style={{ width: '20px' }} />
                </button>
            </div>
            <div className="shoutout_text_heading">
                <h2>Question 1 of 3</h2>
                <h3>Tell us why you selected our company for payment processing?</h3>
            </div>
            <form onSubmit={handleSubmit(captureData)}>
                <div className='shoutout_text_form' data-tour='resizable-parent'>
                    <img
                        className='backgroundPlaceholder'
                        src={designConfig.text_testimonial_bg}
                        alt=''
                    />
                    <div className='contentWrap' data-tut='reactour__goTo'>
                        <h4 className='h5'>Answer </h4>
                        <div className='textAreaWrapper'>
                            <textarea
                                placeholder='Type Here...'
                                tabIndex='0'
                                cols='30'
                                rows='10'
                                className={`form-control messageTextarea ${
                                    errors.message ? 'is-invalid' : ''
                                }`}
                                maxLength={MAX_COUNT}
                                {...rest}
                                ref={(e) => {
                                    ref(e);
                                    messageRef.current = e; // you can still assign to ref
                                }}
                                onChange={(e) => handleTextCounter(e)}></textarea>
                            <div className='char_counter'>
                                {MAX_COUNT - charCount} character left.
                            </div>
                        </div>
                        <input
                            tabIndex='-1'
                            type='text'
                            {...register('fullName', { required: 'Full Name is reuiqred' })}
                            className={`form-control fullNameText ${
                                errors.fullName ? 'is-invalid' : ''
                            }`}
                        />
                        <input
                            tabIndex='-2'
                            type='text'
                            placeholder='Optional: Type your company name and title'
                            className={`form-control designationText ${
                                errors.destination ? 'is-invalid' : ''
                            }`}
                            {...register('designation')}
                        />
                    </div>
                </div>

                <div className='shoutout_note_btn'>
                    <button
                        type='submit'
                        className='btn btn-primary btn-block'
                        style={{
                            backgroundColor: designConfig.btn_bg_color,
                            borderColor: designConfig.btn_bg_color,
                            color: designConfig.btn_text_color,
                        }}>
            Continue
                    </button>
                </div>
            </form>
        </div>
    );

});

export default TextShoutout;


