import React, { useEffect, 
  // useState 
} from 'react';
import { 
  // useDispatch, 
  useSelector } from 'react-redux';
import {
    // changeScreen,
    // resetState,
    getDesignConfig,
    // selectRecord,
} from '../features/studio/studioSlice';
// import { MainLogo, CopyRights } from '../components';
import Lottie from 'react-lottie';
import { useForm } from 'react-hook-form';
import * as animationData from '@src/assets/lottie/lf30_z1sghrbu.json';
import { useNavigate } from 'react-router-dom';
// import {
//     FacebookShareButton,
//     LinkedinShareButton,
//     TwitterShareButton,
//     WhatsappShareButton,
//     WhatsappIcon,
// } from 'react-share';
// import { Laravel } from '..';
import MainLayout from '@src/layout/MainLayout';


export const Thanks = () => {
    // fb app id
    // 893218884216768
    // const [ShareUrl, setShareUrl] = useState('');
    // const record = useSelector(selectRecord);
    // const facebook = require('@src/assets/images/facebook.svg').default;
    // const facebook_messenger =
    // require('@src/assets/images/facebook_messenger.svg').default;
    // const instagram = require('@src/assets/images/instagram.svg').default;
    // const twitter = require('@src/assets/images/twitter.svg').default;
    // const linkedIn = require('@src/assets/images/linkedIn.svg').default;
    // const tiktok = require('@src/assets/images/tiktok.svg').default;

    // let ShareUrl = 'https://stori.cloud/webhub/feed/80222741';
    // const dispatch = useDispatch();
    const navigate = useNavigate(); console.log(navigate);

    const storiurl = process.env.REACT_APP_HUB_URL;

    const designConfig = useSelector(getDesignConfig);
    const bgColor = designConfig.btn_bg_color;
    const btnTextColor = designConfig.btn_text_color;
    //
    const thankyou_headline = designConfig.thankyou_headline;
    const thankyou_message = designConfig.thankyou_message;
    const thankyou_calltoaction = designConfig.thankyou_calltoaction;
    const display_thankyou_calltoaction = (thankyou_calltoaction) ? 'block' : 'none';
    //

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
  
      //console.log(data);

      window.location.href = storiurl+'/register?email='+data.email;
      
    };

    const goToExitPage = () => {
      navigate('/');
    };

    useEffect(() => {
    // console.log('selectRecord');
    // console.log(record);
        // setShareUrl(`${Laravel.siteUrl}/webhub/approved/${record?.id}`);
    }, []);

    // const letsDoAnother = () => {
    //     dispatch(resetState());
    //     dispatch(changeScreen('welcome'));
    //     navigate('/welcome');
    // };

    return (
        <MainLayout>
            <div className='shoutout_thankyou_box'>
                <div className='shoutout_thanks_wrap'>
                    <Lottie options={defaultOptions} height={180} width={180} />
                    <h2 className='h1'>That&apos;s a wrap! </h2>
                    <h3>{thankyou_headline}</h3>
                    {/* <span
              className='line-seperator'
              style={{
                backgroundColor: designConfig.body_text_color,
              }}></span> */}
                    <p>{thankyou_message}</p>

                    {/* <div className='social_icons'>
              <ul>
                <li>
                  <FacebookShareButton url={ShareUrl}>
                    <img alt='' src={facebook} />
                  </FacebookShareButton>
                </li>

                <li>
                  <TwitterShareButton url={ShareUrl}>
                    <img alt='' src={twitter} />
                  </TwitterShareButton>
                </li>
                <li>
                  <LinkedinShareButton url={ShareUrl}>
                    <img alt='' src={linkedIn} />
                  </LinkedinShareButton>
                </li>
                <li>
                  <WhatsappShareButton url={ShareUrl}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>
                </li>
                <li>
                  <a href='#' rel='noreferrer'>
                    <img alt='' src={instagram} />
                  </a>
                </li>
                <li>
                  <a href='#' rel='noreferrer'>
                    <img alt='' src={tiktok} />
                  </a>
                </li>
              </ul>
            </div> */}
                    {/* <br />
            <button
              className='btn btn-primary btn-block'
              onClick={letsDoAnother}
              style={{
                backgroundColor: designConfig.btn_bg_color,
                borderColor: designConfig.btn_bg_color,
                color: designConfig.btn_text_color,
              }}>
              I'm done here. <strong>Exit</strong>
            </button> */}
                </div>
                <div className="thankyou_form_box" style={{display: display_thankyou_calltoaction}}>
                    <div className="thankyou_header">
                        <p>Sharing Happens on the email, we can&apos;t do it from here</p>
                    </div>
                    <div className="thankyou_content">
                        <h6>Start creating video with ShoutOut today</h6>
                        <form id="shoutoutForm" name="shoutoutForm" onSubmit={handleSubmit(onSubmit)}>
                          <div className='form-group'>
                                <input
                                    className={
                                        errors.email
                                            ? 'form-control input-danger'
                                            : 'form-control'
                                    }
                                    type='email'
                                    placeholder='Email Address'
                                    defaultValue=''
                                    {...register('email', {
                                        required: true,
                                        pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,  // eslint-disable-line
                                    })}
                                />
                                {errors.email && (
                                    <p className='danger'>Please enter correct email.</p>
                                )}
                          </div>
                          <div className='form-group'>
                              <button
                                  type='submit'
                                  className='btn btn-primary btn-lg btn-block'
                                  style={{
                                      backgroundColor: bgColor,
                                      borderColor: bgColor,
                                      color: btnTextColor,
                                  }}>
                                  Get Started
                              </button>
                          </div>
                          <div className='form-group'>
                              <button
                                  type='button'
                                  onClick={() => goToExitPage()}
                                  className='btn btn-primary btn-lg btn-block'
                                  style={{
                                      backgroundColor: bgColor,
                                      borderColor: bgColor,
                                      color: btnTextColor,
                                  }}>
                                  I am done here <strong>Exit</strong>
                              </button>
                          </div>
                        </form>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
  
};
