// import React, { useEffect, 
//   // useState 
// } from 'react';
import { 
  // useDispatch, 
  useSelector } from 'react-redux';
import {
    // changeScreen,
    // resetState,
    getDesignConfig,
    // selectRecord,
} from '../features/studio/studioSlice';
// import { MainLogo, CopyRights } from '../components';
// import Lottie from 'react-lottie';
// import { useForm } from 'react-hook-form';
// import * as animationData from '@src/assets/lottie/lf30_z1sghrbu.json';
import { useNavigate } from 'react-router-dom';
// import {
//     FacebookShareButton,
//     LinkedinShareButton,
//     TwitterShareButton,
//     WhatsappShareButton,
//     WhatsappIcon,
// } from 'react-share';
// import { Laravel } from '..';
import MainLayout from '@src/layout/MainLayout';


export const RecordText = () => {
    // fb app id
    // 893218884216768
    // const [ShareUrl, setShareUrl] = useState('');
    // const record = useSelector(selectRecord);
    // const facebook = require('@src/assets/images/facebook.svg').default;
    // const facebook_messenger =
    // require('@src/assets/images/facebook_messenger.svg').default;
    // const instagram = require('@src/assets/images/instagram.svg').default;
    // const twitter = require('@src/assets/images/twitter.svg').default;
    // const linkedIn = require('@src/assets/images/linkedIn.svg').default;
    // const tiktok = require('@src/assets/images/tiktok.svg').default;

    // let ShareUrl = 'https://stori.cloud/webhub/feed/80222741';
    // const dispatch = useDispatch();
    const navigate = useNavigate(); console.log(navigate);

    // const storiurl = process.env.REACT_APP_HUB_URL;

    const designConfig = useSelector(getDesignConfig);
    const bgColor = designConfig.btn_bg_color;
    const btnTextColor = designConfig.btn_text_color;
    //
    // const thankyou_headline = designConfig.thankyou_headline;
    // const thankyou_message = designConfig.thankyou_message;
    const thankyou_calltoaction = designConfig.thankyou_calltoaction;
    const display_thankyou_calltoaction = (thankyou_calltoaction) ? 'block' : 'none';
    //

    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationData,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice',
    //     },
    // };

    // const {
    //   register,
    //   handleSubmit,
    //   formState: { errors },
    // } = useForm();

    // const onSubmit = (data) => {
  
    //   //console.log(data);

    //   window.location.href = storiurl+'/register?email='+data.email;
      
    // };

    // const goToExitPage = () => {
    //   navigate('/');
    // };

    // useEffect(() => {
    // console.log('selectRecord');
    // console.log(record);
        // setShareUrl(`${Laravel.siteUrl}/webhub/approved/${record?.id}`);
    // }, []);

    // const letsDoAnother = () => {
    //     dispatch(resetState());
    //     dispatch(changeScreen('welcome'));
    //     navigate('/welcome');
    // };

    return (
        <MainLayout>
            <div className='shoutout_thankyou_box record_box'>
                <div className='shoutout_RecordText_wrap'>
                    {/* <Lottie options={defaultOptions} height={180} width={180} /> */}
                    <div className='QuestionHeading'>
                      <h3>Question 1 of 3</h3>
                      <h4>Tell us why you selected our company for payment processing?</h4>
                    </div>
                </div>
                <div className="thankyou_form_box" style={{display: display_thankyou_calltoaction}}>
                    <div className="thankyou_content">
                        <h5>Answer</h5>
                        <form id="shoutoutForm" name="shoutoutForm">
                          <div className='form-group'>
                                  {/* <input
                                    className={
                                        errors.email
                                            ? 'form-control input-danger'
                                            : 'form-control'
                                    }
                                    type='email'
                                    placeholder='Email Address'
                                    defaultValue=''
                                    {...register('email', {
                                        required: true,
                                        pattern:
                            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,  // eslint-disable-line
                                    })}
                                /> */}
                                <textarea className="form-control" rows="5" placeholder='Type here'></textarea> 
                          </div>
                          <div className='form-group'>
                            <input className="form-control" type='text' placeholder='Title'/>
                          </div>
                          <div className='form-group'>
                            <input className="form-control" type='text' placeholder='Optional: Company Name'/>
                          </div>
                          <div className='form-group'>
                              <button
                                  type='submit'
                                  className='btn btn-primary btn-lg btn-block'
                                  style={{
                                      backgroundColor: bgColor,
                                      borderColor: bgColor,
                                      color: btnTextColor,
                                  }}>
                                  Continue
                              </button>
                          </div>
                          {/* <div className='form-group'>
                              <button
                                  type='button'
                                  onClick={() => goToExitPage()}
                                  className='btn btn-primary btn-lg btn-block'
                                  style={{
                                      backgroundColor: bgColor,
                                      borderColor: bgColor,
                                      color: btnTextColor,
                                  }}>
                                  I am done here <strong>Exit</strong>
                              </button>
                          </div> */}
                        </form>
                    </div>
                </div>
                <div className='recordcontent'>
                  <h5>Answer</h5>
                  <p>I think you should watch Fassel&apos;s videos because he&apos;s showing us the best restaurants in town and everything he says. It&apos;s great. I recommend watching his videos follow him now.</p>
                  <p>I think you can improve your performance by creating more videos.</p>
                  <button className='btn btn-primary btn-block'>
                    <span>Continue</span>
                  </button>
                </div>
            </div>
        </MainLayout>
    );
  
};
