import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { useSelector } from 'react-redux';
import {
    getDesignConfig
} from '../features/studio/studioSlice';
//import { useNavigate } from 'react-router-dom';
import MainLayout from '@src/layout/MainLayout';

export const CalenderPage = () => {
    
    const designConfig = useSelector(getDesignConfig);
    //const navigate = useNavigate();

    const btnStyle = {
        backgroundColor: designConfig.btn_bg_color,
        borderColor: designConfig.btn_bg_color,
        color: designConfig.btn_text_color,
    };

    const [value, onChange] = useState(new Date());
    return (
        <MainLayout uploadingClass="calender-section">
            <div className='calender_box'>
                <h3> <span className='cal_emoji'>🤞</span> I promise to create a ShoutOut on this day...</h3>
                <Calendar onChange={onChange} value={value} />
                <a
                    className='btn btn-primary btn-block'
                    href="https://calendar.google.com/calendar/" 
                    /* onClick={() => navigate('https://calendar.yahoo.com/')} */
                    style={btnStyle}>
                    <span>Google Calendar</span>
                </a>

                <a
                    className='btn btn-primary btn-block'
                    href="https://calendar.yahoo.com/" 
                    /* onClick={() => navigate('https://calendar.yahoo.com/')} */
                    style={btnStyle}>
                    <span>Yahoo! Calendar</span>
                </a>

                <a
                    className='btn btn-primary btn-block'
                    href="https://www.calendar.com/office-365-calendar/" 
                    /* onClick={() => navigate('https://www.calendar.com/office-365-calendar/')} */
                    style={btnStyle}>
                    <span>Outlook Calendar</span>
                </a>

                {/* <a
                    className='btn btn-primary btn-block'
                    href="https://codesandbox.io/s/2cv16/" 
                    onClick={() => navigate('https://codesandbox.io/s/2cv16')}
                    style={btnStyle}>
                    <span>Download .Ics</span>
                </a> */}
            
            </div>
        </MainLayout>
    );
};