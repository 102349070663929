import React, { useState } from 'react';
import { 
    //useDispatch, 
    useSelector 
} from 'react-redux';

import {
    getDesignConfig,
    setExperience,
} from '../features/studio/studioSlice';
import { useNavigate } from 'react-router-dom';
import TwoColumnLayout from '@src/layout/TwoColumnLayout';

export const GetStart = () => {
    //const dispatch = useDispatch();
    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    //const [exp, setExp] = useState(null);
    const [exp, setExp] = useState('video'); console.log(setExp); console.log(exp);
    const [agree, setAgree] = useState(false); console.log(setAgree); console.log(agree);
    //
    const shoutout_questions_count = designConfig.shoutout_questions_count;
    const shoutout_questions_total_time = designConfig.shoutout_questions_total_time;
    const shoutout_questions_data = designConfig.shoutout_questions_data;
    //console.log(shoutout_questions_data.length);
    const qtext = (shoutout_questions_count > 1) ? 'questions' : 'question';
    const mtext = (shoutout_questions_total_time > 1) ? 'minutes' : 'minute';
    //
    const show_continue_btn = (shoutout_questions_data.length) ? 'block' : 'none';
    const show_question_notice = (shoutout_questions_data.length) ? 'none' : 'block';
    //
    const navigate = useNavigate();

    const setExperiences = () => {
        //dispatch(setExperience(exp));
        setExperience(exp);
        navigate('/booth');
    };

    const bodyTextColor = designConfig.body_text_color;
    const btnBgColor = designConfig.btn_bg_color;
    const btnTextColor = designConfig.btn_text_color;

    return (

        <TwoColumnLayout loading={false} body_dark_color="#4B04B8">

            <div className='shoutout_getStart_content'>

                <div className="question_box">
                    <div className="question_heading">
                        <h2 style={{ color: bodyTextColor }}>Check out your questions</h2>
                        <h6 style={{ color: bodyTextColor }}>{shoutout_questions_count} {qtext} - {shoutout_questions_total_time} {mtext}</h6>
                    </div>
                    <div className="question_listing">
                        <ul>
                          {shoutout_questions_data.map((item, index) =>
                              <li key={item.id}>
                                <div className="question_listing">
                                    <span>{index+1}</span>
                                    <p style={{ color: bodyTextColor }}>{item.question_text}</p>
                                </div>
                              </li>
                          )}                            
                           
                        </ul> 
                    </div>
                </div>

                <div className='shoutout_exprience_btnGroup'>
                    
                    <div className="shoutout_respond_btn">
                        <div className="radio_btn_item">
                            <input type="radio" name="exprience" id="video" value="video" onChange={(e) => setExp(e.target.value)} />
                            <label htmlFor="video" style={ exp == 'video' ? { backgroundColor: btnBgColor } : {}}>
                                <div className="radio_box">
                                    <svg 
                                        width='26' 
                                        height='17' 
                                        viewBox='0 0 26 17' 
                                        fill='none' 
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path style={ exp == 'video' ? { fill: btnTextColor } : {fill: bodyTextColor}} d="M24.417 2.38019C23.9509 2.04119 23.3152 1.99881 22.8067 2.25307L18.442 4.24473V3.43959C18.442 1.95644 17.2131 0.727539 15.73 0.727539H3.52577C2.00024 0.727539 0.813721 1.95644 0.813721 3.43959V14.2878C0.813721 15.8133 2.00024 16.9998 3.52577 16.9998H15.73C17.2131 16.9998 18.442 15.8133 18.442 14.2878V13.4826L22.8067 15.5167C23.0186 15.6014 23.2729 15.6862 23.4847 15.6862C23.8237 15.6862 24.1628 15.559 24.417 15.3895C24.9255 15.0929 25.2221 14.542 25.2221 13.9488V3.77859C25.2221 3.22771 24.9255 2.67682 24.417 2.38019ZM16.408 14.2878C16.408 14.6692 16.069 14.9658 15.73 14.9658H3.52577C3.14439 14.9658 2.84776 14.6692 2.84776 14.2878V3.43959C2.84776 3.10058 3.14439 2.76157 3.52577 2.76157H15.73C16.069 2.76157 16.408 3.10058 16.408 3.43959V14.2878ZM23.1881 13.4403L18.442 11.2791V6.49064L23.1881 4.32948V13.4403Z" fill="white"/>
                                    </svg>
                                    <span style={ exp == 'video' ? { color: btnTextColor } : {color: bodyTextColor}}>Video</span>
                                </div>
                            </label>
                        </div>

                        <div className="radio_btn_item">
                            <input type="radio" name="exprience" id="audio" value="audio" onChange={(e) => setExp(e.target.value)} />
                            <label htmlFor="audio" style={ exp == 'audio' ? { backgroundColor: btnBgColor } : {}}>
                                <div className="radio_box">
                                    <svg 
                                        width='16'
                                        height='22' 
                                        viewBox='0 0 16 22" fill="none' 
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path style={ exp == 'audio' ? { fill: btnTextColor } : {fill: bodyTextColor}} d="M14.4591 8.15177C13.8658 8.15177 13.442 8.6179 13.442 9.16878V10.8638C13.442 14.042 10.6876 16.5845 7.46706 16.2879C4.62789 15.9913 2.59385 13.4911 2.59385 10.6519V9.16878C2.59385 8.6179 2.12772 8.15177 1.57683 8.15177C0.983572 8.15177 0.559814 8.6179 0.559814 9.16878V10.5248C0.559814 14.2962 3.27186 17.6863 7.00093 18.1948V19.678H4.92452C4.37363 19.678 3.94987 20.1441 3.94987 20.695C3.94987 21.2035 4.37363 21.712 4.92452 21.712H11.0266C11.6199 21.712 12.086 21.2882 12.086 20.695C12.086 20.1441 11.6199 19.678 11.069 19.678H9.03496V18.2796C12.6369 17.7711 15.4761 14.6353 15.4761 10.8638V9.16878C15.4761 8.6179 15.0099 8.15177 14.4591 8.15177ZM8.01794 14.9319C10.2639 14.9319 12.086 13.1097 12.086 10.8638V4.0837C12.086 1.88016 10.2639 0.015625 8.01794 0.015625C5.77203 0.015625 3.94987 1.83778 3.94987 4.0837V10.8638C3.94987 13.1097 5.77203 14.9319 8.01794 14.9319ZM5.98391 4.0837C5.98391 2.98193 6.8738 2.04966 8.01794 2.04966C9.11971 2.04966 10.052 2.98193 10.052 4.0837V10.8638C10.052 12.008 9.11971 12.8979 8.01794 12.8979C6.8738 12.8979 5.98391 12.008 5.98391 10.8638V4.0837Z" fill="#C8C8C8"/>
                                    </svg>
                                    <span style={ exp == 'audio' ? { color: btnTextColor } : {color: bodyTextColor}}>Audio</span>
                                </div>
                            </label>
                        </div>

                        <div className="radio_btn_item">
                            <input type="radio" name="exprience" id="text" value="text" onChange={(e) => setExp(e.target.value)} />
                            <label htmlFor="text" style={ exp == 'text' ? { backgroundColor: btnBgColor } : {}}>
                                <div className="radio_box">
                                    <svg 
                                        width="20" 
                                        height="20" 
                                        viewBox="0 0 20 20"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path style={ exp == 'text' ? { fill: btnTextColor } : {fill: bodyTextColor}} d="M19.51 1.3886V4.77866C19.51 5.37192 19.0438 5.79568 18.4929 5.79568C17.8997 5.79568 17.4759 5.37192 17.4759 4.77866V2.40562H11.0348V17.3219H13.0689C13.6197 17.3219 14.0859 17.788 14.0859 18.3389C14.0859 18.9322 13.6197 19.3559 13.0689 19.3559H6.96675C6.37349 19.3559 5.94973 18.9322 5.94973 18.3389C5.94973 17.788 6.37349 17.3219 6.96675 17.3219H9.00078V2.40562H2.55967V4.77866C2.55967 5.37192 2.09354 5.79568 1.54265 5.79568C0.949392 5.79568 0.525635 5.37192 0.525635 4.77866V1.3886C0.525635 0.837715 0.949392 0.371582 1.54265 0.371582H18.4929C19.0438 0.371582 19.51 0.837715 19.51 1.3886Z" fill="#C8C8C8"/>
                                    </svg>
                                    <span style={ exp == 'text' ? { color: btnTextColor } : {color: bodyTextColor}}>Text</span>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>

                {/* <div className="shoutout_legal_part">
                    <h3 style={{color: bodyTextColor }}>The necessary legal part...</h3>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"  id="flexCheckChecked"  onChange={() => { setAgree(!agree); setExp('video');} } />
                        <label className="form-check-label" htmlFor="flexCheckChecked">
                I agree to the <span>content submission agreement</span>
                        </label>
                    </div>
                </div> */}
                <div className="continue_btn_wrap">
                    <button
                        className='btn btn-primary btn-block'
                        /* disabled={!(agree && exp)} */
                        onClick={() => setExperiences('video')}
                        style={{
                            backgroundColor: btnBgColor,
                            borderColor: btnBgColor,
                            color: btnTextColor,
                            display: show_continue_btn,
                        }}>
                        <span>Continue</span>
                    </button>
                    <p style={{display : show_question_notice}}>Please add Questions!</p>
                </div>     
            </div>
        </TwoColumnLayout>
    );
};
