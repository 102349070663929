import React, { 
    useState,
    useEffect
} from 'react';

import { useSelector } from 'react-redux';
import {
    getDesignConfig,
    //selectExperience
} from '../features/studio/studioSlice';
//import { useNavigate } from 'react-router-dom';
import MainLayout from '@src/layout/MainLayout';

export const HardwareTest = () => {

    let localstream; console.log(localstream);

    useEffect(() => {
        let vid = document.getElementById("vid");
        if (navigator.mediaDevices.getUserMedia !== null) {
          var options = {
            video: true,
            audio: true
          };
          navigator.getUserMedia(
            options,
            function (stream) {
              vid.srcObject = stream;
              localstream = stream;
              vid.play();
              console.log(stream, "streaming");
            },
            function (e) {
              console.log("background error : " + e.name);
            }
          );
        }
      });


    //const navigate = useNavigate();

    //const experience = useSelector(selectExperience);  //console.log(experience);
    const designConfig = useSelector(getDesignConfig); //console.log(designConfig);
    const show_settings_contactus = designConfig.show_settings_contactus;
    const settings_contactus = designConfig.settings_contactus;
    //
    const [AudioInputValue, selectAudioInputValue]      = useState(null); //console.log(AudioInputValue);
    const [VideoInputValue, selectVideoInputValue]      = useState(null); //console.log(VideoInputValue);
    const [AudioOutputValue, selectAudioOutputValue]    = useState(null); //console.log(AudioOutputValue);
    //
    const onChangeAudioInput = (event) => {
        selectAudioInputValue(event.target.value);
        checkHardware();
    };
    const onChangeVideoInput = (event) => {
        selectVideoInputValue(event.target.value);
        checkHardware();
    };
    const onChangeAudioOutput = (event) => {
        selectAudioOutputValue(event.target.value);
        checkHardware();
    };
    //
    const checkHardware = () => {
        if(!AudioInputValue || !VideoInputValue || !AudioOutputValue){
            return false;
        } else {
            return true;
        }
    };
    //
    // console.log(AudioInputValue);
    // console.log(VideoInputValue);
    // console.log(AudioOutputValue);
    //
    const setHardware = () => {
        /* if (experience == 'text') {
            navigate('/textCapture');
        }
        else if (experience == 'audio') {
            navigate('/record');
        }
        else {
            navigate('/booth');
        } */
    };
    //
    const btnStyle = {
        backgroundColor: designConfig.btn_bg_color,
        borderColor: designConfig.btn_bg_color,
        color: designConfig.btn_text_color,
    }; //console.log(btnStyle);
    //
    const showContactusBtn = () => {
        //console.log("show_settings_contactus: " + show_settings_contactus);
        //console.log("settings_contactus: " + settings_contactus);
        if(show_settings_contactus && settings_contactus){
            return (<div className='contact_support'>
                <span>Need help? <a target='_blank' rel="noreferrer" href={settings_contactus}>Contact Support</a></span>
                </div>);
        } else {
            return '';
        }
    };

    //----------------------------------------------------------------------

    const listDevices = async () => {
        const devices = await navigator.mediaDevices?.enumerateDevices?.(); //console.log(devices);
        if (devices) {
            const video = [];
            const audio = [];
            const mic   = [];
            for (const device of devices) {
                //console.log(device.kind);
                switch (device.kind) {
                    case 'videoinput':
                        video.push(device);
                        break;
                    case 'audioinput':
                        audio.push(device);
                        break;
                    case 'audiooutput':
                        mic.push(device);
                        break;
                }
            }

            //console.log('videoinput devices: ' + video.length);
            //console.log('audioinput devices: ' + audio.length);
            //console.log('audiooutput devices: ' + mic.length);

            return { video, audio, mic };
        } else {
            throw new Error('No support for multimedia devices.');
        }
    };
    const [error, setError] = React.useState(null);
    const [devices, setDevices] = React.useState(null);
    React.useEffect(() => {
        const promise = listDevices();  // <--- lists available input audio and input video devices
        promise
          .then((devices) => setDevices(devices))
          .catch((error) => setError(error));
    }, []);

    //console.log(error);

    //----------------------------------------------------------------------






    return (
        <MainLayout>

        {error && (<div className='device_test'><h2>{error}</h2></div>)}

          {/* {devices && (
            <>
              {devices.video.length === 0 && (<div>Video devices: not detected!</div>)}
              
              {devices.video.length > 0 && (
                <>
                  <div>Video devices:</div>
                  <ul>
                    {devices.video.map((videoDevice) => (
                      <li key={videoDevice.deviceId}>
                        <div>deviceId: {videoDevice.deviceId}</div>
                        <div>kind: {videoDevice.kind}</div>
                        <div>label: {videoDevice.label}</div>
                        <div>groupId: {videoDevice.groupId}</div>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {devices.audio.length === 0 && (<div>Audio devices: not detected!</div>)}

              {devices.audio.length > 0 && (
                <>
                  <div>Audio devices:</div>
                  <ul>
                    {devices.audio.map((audioDevice) => (
                      <li key={audioDevice.deviceId}>
                        <div>deviceId: {audioDevice.deviceId}</div>
                        <div>kind: {audioDevice.kind}</div>
                        <div>label: {audioDevice.label}</div>
                        <div>groupId: {audioDevice.groupId}</div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              </>
          )} */}





            <div className='device_test'>
                <h2>Let’s check if everything is working fine</h2>

                <div className="device_test_box">
                    <div className="camera_box">
                        
                        <video id="vid" autoPlay></video>
                    
                    </div>
                    {/* <span className='fps_test'>720p // 30fps</span> */}

                    {devices && devices.mic.length > 0 && (
                        <>

                        <div className='device_test_list_box'>
                            <div className='device_test_header'>
                                <div className='device_icon'>
                                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="white" d="M7 12.4688C8.81152 12.4688 10.2812 11.0332 10.2812 9.1875V3.71875C10.2812 1.90723 8.81152 0.4375 7 0.4375C5.1543 0.4375 3.71875 1.90723 3.71875 3.71875V9.1875C3.71875 10.999 5.1543 12.4688 7 12.4688ZM12.1953 7C11.7168 7 11.375 7.37598 11.375 7.82031V9.1875C11.375 11.7168 9.25586 13.7334 6.69238 13.5625C4.43652 13.4258 2.625 11.3066 2.625 9.0166V7.82031C2.625 7.37598 2.24902 7 1.80469 7C1.32617 7 0.984375 7.37598 0.984375 7.82031V8.91406C0.984375 11.9561 3.1377 14.6904 6.17969 15.1006V16.2969H4.8125C4.16309 16.2969 3.68457 16.8438 3.71875 17.459C3.71875 17.7324 3.95801 17.9375 4.26562 17.9375H9.73438C10.0078 17.9375 10.2471 17.7324 10.2471 17.459C10.2812 16.8438 9.80273 16.2969 9.1875 16.2969H7.82031V15.1689C10.7256 14.7588 13.0156 12.2295 13.0156 9.1875V7.82031C13.0156 7.37598 12.6396 7 12.1953 7Z"/>
                                    </svg>
                                </div>
                                <select className='form-select' onChange={onChangeAudioInput} >
                                    <option key='0' value=''>Please Select Audio Input</option>
                                    {devices.mic.map((micDevice) => (
                                        <><option key={micDevice.deviceId} value={micDevice.deviceId}>{micDevice.label}</option></>
                                    ))}
                                </select>
                            </div>
                        </div>

                        </>
                    )}

                    {devices && devices.video.length > 0 && (
                        <>
                        
                        <div className='device_test_list_box'>
                            <div className='device_test_header'>
                                <div className='device_icon'>
                                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13.2812 2.26562V12.1094C13.2812 13.0322 12.5293 13.75 11.6406 13.75H1.79688C0.874023 13.75 0.15625 13.0322 0.15625 12.1094V2.26562C0.15625 1.37695 0.874023 0.625 1.79688 0.625H11.6406C12.5293 0.625 13.2812 1.37695 13.2812 2.26562ZM19.8438 2.8125V11.5967C19.8438 12.4512 18.8184 12.9639 18.1006 12.4854L14.375 9.8877V4.52148L18.1006 1.92383C18.8184 1.44531 19.8438 1.95801 19.8438 2.8125Z" fill="white"/>
                                    </svg>
                                </div>
                                <select className='form-select' onChange={onChangeVideoInput} >
                                    <option key='1' value=''>Please Select Video Input</option>
                                    {devices.video.map((videoDevice) => (
                                        <><option key={videoDevice.deviceId} value={videoDevice.deviceId}>{videoDevice.label}</option></>
                                    ))}
                                </select>
                            </div>
                        </div>

                        </>
                    )}

                    {devices && devices.audio.length > 0 && (
                        <>

                        <div className='device_test_list_box'>
                            <div className='device_test_header'>
                                <div className='device_icon'>
                                    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.332 5.65796C13.9902 5.38452 13.4775 5.45288 13.1699 5.79468C12.8965 6.13647 12.9307 6.64917 13.3066 6.95679C13.6826 7.2644 13.9219 7.70874 13.9219 8.18726C13.9219 8.69995 13.6826 9.14429 13.3066 9.4519C12.9307 9.75952 12.8965 10.2722 13.1699 10.614C13.3408 10.8191 13.5801 10.9216 13.8193 10.9216C13.9902 10.9216 14.1953 10.8533 14.332 10.7507C15.1182 10.1013 15.5625 9.17847 15.5625 8.18726C15.5625 7.23022 15.1182 6.30737 14.332 5.65796ZM16.417 3.12866C16.041 2.85522 15.5283 2.92358 15.2549 3.26538C14.9473 3.60718 15.0156 4.11987 15.3574 4.42749C16.5195 5.35034 17.2031 6.75171 17.2031 8.18726C17.2031 9.65698 16.5195 11.0583 15.3574 11.9812C15.0156 12.2888 14.9473 12.8015 15.2549 13.1433C15.4258 13.3484 15.6309 13.4509 15.8701 13.4509C16.0752 13.4509 16.2461 13.3826 16.417 13.28C17.9551 12.0154 18.8438 10.1697 18.8438 8.18726C18.8438 6.23901 17.9551 4.39331 16.417 3.12866ZM10.5381 0.633545C10.1279 0.462646 9.68359 0.531006 9.3418 0.838623L4.72754 4.90601H1.89062C0.967773 4.90601 0.25 5.65796 0.25 6.54663V9.7937C0.25 10.6824 0.967773 11.4001 1.89062 11.4001H4.72754L9.3418 15.5017C9.54688 15.7751 9.82031 15.8435 10.0938 15.8435C10.2305 15.8435 10.3672 15.8435 10.5381 15.7751C10.9141 15.5701 11.1875 15.1941 11.1875 14.7498V1.62476C11.1875 1.2146 10.9141 0.804443 10.5381 0.633545Z" fill="white"/>
                                    </svg>
                                </div>
                                <select className='form-select' onChange={onChangeAudioOutput} >
                                    <option key='2' value=''>Please Select Audio Output</option>
                                    {devices.audio.map((audioDevice) => (
                                        <><option key={audioDevice.deviceId} value={audioDevice.deviceId}>{audioDevice.label}</option></>
                                    ))}
                                </select>
                            </div>  
                        </div>

                        </>
                    )}

                </div>

                <button
                    className='btn btn-primary btn-block' 
                    disabled={!checkHardware()}
                    onClick={() => setHardware()}
                    style={btnStyle}>
                    <span>Continue</span>
                </button>

                {showContactusBtn()}
                
            </div>




        </MainLayout>
    );
};