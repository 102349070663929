import { useSelector  } from 'react-redux';
//import { useDispatch, useSelector  } from 'react-redux';
//import { getDesignConfig, changeScreen } from '../features/studio/studioSlice';
import { getDesignConfig } from '../features/studio/studioSlice';
import { CopyRights, MainLogo, OffCanvas } from '../components';

import { useNavigate, useLocation } from 'react-router-dom';

//import {Route, Link, Routes, useLocation} from 'react-router-dom';

function MainLayout({children, body_bg_color, uploadingClass}) {
    const designConfig = useSelector(getDesignConfig);
    const bodyBgColor = body_bg_color || designConfig.body_bg_color;
    //const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const location = useLocation();
    const displayBackBtn = (location.pathname != '/' && location.pathname != '/thanks' && location.pathname != '/legal') ? 'block' : 'none';
    console.log('location'); console.log(location.pathname);
    const decsionClass = (location.pathname == '/decision') ? 'decsion_box' : '';

    return ( 
        <div className={`shoutout_content_section ${decsionClass}  ${uploadingClass}`} style={{backgroundColor: bodyBgColor,}}>
            <OffCanvas />
            <MainLogo />

            <div className="back_btn" style={{display: displayBackBtn,}}>
                <button
                    className='btn btn-dark note_btn_back'
                    onClick={() => {
                        //dispatch(changeScreen('welcome'));
                        navigate(-1);
                    }}>
                    <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.60993 0.380558C7.82181 0.592436 7.94894 0.846691 7.94894 1.10095C7.94894 1.39758 7.82181 1.65183 7.60993 1.82133L3.58424 5.84703H20.8312C21.382 5.84703 21.8482 6.31316 21.8482 6.86405C21.8482 7.45731 21.382 7.88106 20.8312 7.88106H3.58424L7.60993 11.9491C8.03369 12.3305 8.03369 12.9662 7.60993 13.3475C7.22855 13.7713 6.59292 13.7713 6.21153 13.3475L0.448433 7.58443C0.0246759 7.20305 0.0246759 6.56742 0.448433 6.18603L6.21153 0.422934C6.59292 -0.000823677 7.22855 -0.000823975 7.60993 0.380558Z" fill="white"/>
                    </svg>
                </button>
            </div>
            

            <div className='shoutout_content_wrap'>
                <div className='shoutout_content_box'>
                    {children}
                </div>
                <CopyRights/>
            </div>
        </div>

    );
}

MainLayout.defaultProps={
    uploadingClass : ''
}



export default MainLayout;