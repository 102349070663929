export const CameraPermissionError = () => {
    return (
        <div className='shoutout_disable_camra'>
            <div className='shoutout_disable_camra_box'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
                    <path d='M633.9 483.4C640.9 488.9 642 498.1 636.6 505.9C631.1 512.9 621 514 614.1 508.6L6.086 28.56C-.8493 23.08-2.033 13.02 3.443 6.086C8.918-.8493 18.98-2.033 25.91 3.443L633.9 483.4zM459.9 448L500.4 480H128C92.65 480 64 451.3 64 416V160C64 152.4 65.32 145.1 67.74 138.4L96 160.7V416C96 433.7 110.3 448 128 448L459.9 448zM258.6 32H381.4C402.1 32 420.4 45.22 426.9 64.82L437.3 96H512C547.3 96 576 124.7 576 160V376.6L544 351.3V160C544 142.3 529.7 128 512 128H414.3L396.6 74.94C394.4 68.41 388.3 64 381.4 64H258.6C251.7 64 245.6 68.41 243.4 74.94L233.1 105.9L206.4 84.78L213.1 64.82C219.6 45.22 237.9 32 258.6 32H258.6zM208 288C208 275.9 209.9 264.3 213.4 253.4L241 275.2C240.3 279.4 240 283.6 240 288C240 332.2 275.8 368 320 368C330.9 368 341.3 365.8 350.8 361.9L378.4 383.6C361.4 394 341.4 400 320 400C258.1 400 208 349.9 208 288V288zM428.5 260.1L321.1 176C373.3 176.9 416.3 212.4 428.5 260.1z' />
                </svg>
                <h6>You have disabled cemra</h6>
                <p>
          Open your browser setting and allow camera and microphone <br />
          in order to user shoutout app.
                </p>
            </div>
        </div>
    );
};
