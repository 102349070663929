import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeScreen,
    getDesignConfig,
    selectImage,
    setImage,
    selectTestimonial,
} from '../features/studio/studioSlice';
import { CopyRights, Loader } from '../components';
import { fabric } from 'fabric';
import { useNavigate } from 'react-router-dom';

const canvasWidth = 600;
const profileWidth = 190;
const testimonialWidth = canvasWidth - 140;
const nameWidth = canvasWidth - 140;
const designationWidth = canvasWidth - 140;
const fontFamily = 'Helvetica';

const useFabric = (canvas) => {
    const fabricRef = React.useCallback((element) => {
        if (!element) return canvas.current?.dispose();

        canvas.current = new fabric.Canvas(element, {
            hoverCursor: 'pointer',
            selection: false,
            selectionBorderColor: 'blue',
            preserveObjectStacking: true,
            height: canvasWidth,
            width: canvasWidth,
            backgroundColor: 'pink',
            zoom: 2,
            // allowTouchScrolling: true,
        });
    }, []);

    return fabricRef;
};

const toDataUrl = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

export const Text = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const designConfig = useSelector(getDesignConfig);
    const capturedImage = useSelector(selectImage);
    const testimonials = useSelector(selectTestimonial);
    const canvas = useRef(null);
    const fabricRef = useFabric(canvas);

    useEffect(() => {
    //getting cors error from aws s3
        toDataUrl(
            `${designConfig.text_testimonial_bg}?time=${Date.now()}`,
            function (myBase64) {
                //if called directly get canvas image extract error
                var img = new Image();
                img.onload = () => {
                    let loadedImage = new fabric.Image(img, {
                        selectable: false,
                        id: 'mainbg',
                        left: 0,
                        top: 0,
                        crossOrigin: 'Anonymous',
                    });
                    loadedImage.scaleToHeight(canvasWidth);
                    loadedImage.scaleToWidth(canvasWidth);
                    canvas.current.setBackgroundImage(loadedImage);
                    canvas.current.renderAll();
                };
                img.src = myBase64;
            }
        );

        var profileImg = new Image();
        profileImg.onload = () => {
            let loadedImage = new fabric.Image(profileImg, {
                selectable: false,
                id: 'profilePicture',
                left: canvas.current.width / 2 - profileWidth / 2,
                top: 60,
                crossOrigin: 'Anonymous',
            });

            loadedImage.scaleToHeight(profileWidth);
            loadedImage.scaleToWidth(profileWidth);

            canvas.current.add(loadedImage);
            canvas.current.renderAll();
        };
        profileImg.src = capturedImage;
        // profileImg.src = './images/shoutout/desktop.jpg';

        var testimonial = new fabric.Textbox(testimonials.message, {
            fontFamily: fontFamily,
            selectable: true,
            width: testimonialWidth,
            height: 300,
            top: 290,
            left: canvas.current.width / 2 - testimonialWidth / 2,
            fontSize: 22,
            textAlign: 'center',
            fill: 'white',
            lockMovementX: true,
            lockMovementY: true,
            lockUniScaling: true,
            hasControls: false,
        });

        canvas.current.add(testimonial);

        var name = new fabric.Textbox(
            testimonials.firstName + ' ' + testimonials.lastName,
            {
                fontFamily: fontFamily,
                selectable: true,
                width: nameWidth,
                top: 440,
                left: canvas.current.width / 2 - nameWidth / 2,
                fontSize: 20,
                fontWeight: 600,
                textAlign: 'center',
                fill: 'white',
                lockMovementX: true,
                lockMovementY: true,
                hasControls: false,
            }
        );

        canvas.current.add(name);

        if (testimonials.designation != '') {
            var desName = new fabric.Textbox(testimonials.designation, {
                fontFamily: fontFamily,
                selectable: true,
                width: designationWidth,
                top: 470,
                left: canvas.current.width / 2 - designationWidth / 2,
                fontSize: 20,
                textAlign: 'center',
                fill: 'white',
                lockMovementX: true,
                lockMovementY: true,
                hasControls: false,
            });

            canvas.current.add(desName);
        }

        canvas.current.renderAll();

        extractImage();
    }, []);

    const extractImage = () => {
        setTimeout(() => {
            const img = canvas.current.toDataURL({
                format: 'jpeg',
                quality: 0.8,
            });
            dispatch(setImage(img));
            dispatch(changeScreen('decision'));
            navigate('/decision');
        }, 4000);
    };

    return (
        <div className='shoutout_wrap'>
            <div className='shoutout_canvas'>
                <div className='shoutout_content_section'>
                    <div className='shoutout_content_box'>
                        <div className='shoutout_canvas_wrap'>
                            <canvas ref={fabricRef} id='canvasss' />
                        </div>
                        <Loader loading={false} />
                    </div>
                    <CopyRights />
                </div>
            </div>
        </div>
    );
};
